window.addEventListener('DOMContentLoaded', () => {
  // Define elements as variables for easy reading
  const feedbackButton = document.getElementById('feedback-button');
  const feedbackForm = document.getElementById('feedback-form');
  const feedbackFormInner = document.getElementById('feedback-form-inner');
  const feedbackFormBackground = document.getElementById('feedback-form-blur');
  const closeFeedbackButton = document.getElementById('close-feedback-form');
  const submitFeedbackButton = document.getElementById('feedback-form-submit');

  const errorsQuestion1 = document.getElementById('errors-question-1');
  const errorsQuestion2 = document.getElementById('errors-question-2');
  const errorsQuestion3 = document.getElementById('errors-question-3');
  const errorsQuestion4 = document.getElementById('errors-question-4');
  const errorsQuestion5 = document.getElementById('errors-question-5');
  const { body } = document;

  // Open feedback form
  feedbackButton.addEventListener('click',
    () => {
      feedbackForm.classList.add('visible');
      feedbackFormInner.classList.add('visible');
      body.classList.add('lockscroll');
    },
    false);

  // Various methods of closing feedback form
  closeFeedbackButton.addEventListener('click',
    (event) => {
      event.preventDefault();
      feedbackForm.classList.remove('visible');
      body.classList.remove('lockscroll');
    },
    false);

  feedbackFormBackground.addEventListener('click',
    (event) => {
      event.preventDefault();
      feedbackForm.classList.remove('visible');
      body.classList.remove('lockscroll');
    },
    false);

  // Submitting feedback via post request
  submitFeedbackButton.addEventListener('click',
    (event) => {
      event.preventDefault();

      let totalErrors = 0;

      // Check an office type has been selected
      let officeTotal = 0;
      officeTotal += document.querySelector('input[name="area_of_business_office"]').checked;
      officeTotal += document.querySelector('input[name="area_of_business_shop"]').checked;

      // Check we have <= 3 checked elements for the multiple choice options
      let preferTotal = 0;
      preferTotal += document.querySelector('input[name="prefer_adp_homepage"]').checked;
      preferTotal += document.querySelector('input[name="prefer_mobile_app_news"]').checked;
      preferTotal += document.querySelector('input[name="prefer_email"]').checked;
      preferTotal += document.querySelector('input[name="prefer_postcard"]').checked;
      preferTotal += document.querySelector('input[name="prefer_onsite_flyers"]').checked;
      preferTotal += document.querySelector('input[name="prefer_tv_slideshows"]').checked;
      preferTotal += document.querySelector('input[name="prefer_meetings"]').checked;

      let improvementTotal = 0;
      improvementTotal += document.querySelector('input[name="improvement_simplified_content"]').checked;
      improvementTotal += document.querySelector('input[name="improvement_more_tools"]').checked;
      improvementTotal += document.querySelector('input[name="improvement_easier_navigation"]').checked;
      improvementTotal += document.querySelector('input[name="improvement_videos_and_examples"]').checked;
      improvementTotal += document.querySelector('input[name="improvement_regularly_updated_content"]').checked;
      improvementTotal += document.querySelector('input[name="improvement_tailored_information"]').checked;

      errorsQuestion1.classList.remove('visible');
      errorsQuestion2.classList.remove('visible');
      errorsQuestion3.classList.remove('visible');
      errorsQuestion4.classList.remove('visible');
      errorsQuestion5.classList.remove('visible');

      if (document.querySelector('select[name="geographical_location"]').value === 'unselected') {
        totalErrors += 1;
        errorsQuestion1.classList.add('visible');
      }

      if (officeTotal === 0) {
        totalErrors += 1;
        errorsQuestion2.classList.add('visible');
      }

      if (preferTotal > 3) {
        totalErrors += 1;
        errorsQuestion3.classList.add('visible');
      }

      if (document.querySelector('select[name="new_website_easier_to_understand"]').value === 'unselected') {
        totalErrors += 1;
        errorsQuestion4.classList.add('visible');
      }

      if (improvementTotal > 3) {
        totalErrors += 1;
        errorsQuestion5.classList.add('visible');
      }

      if (totalErrors > 0) {
        feedbackFormInner.scrollTop = 0;
      } else {
        submitFeedbackButton.form.submit();
      }
    },
    false);

  // Modal focus overriding
  // Code from w3, available at https://www.w3.org/TR/wai-aria-practices-1.1/examples/dialog-modal/dialog.html

  // Define list of elements inside the modal, as well as some potential future ones
  const focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

  // Select the modal by its id
  const modal = document.querySelector('#feedback-form');

  // Get first element to be focused inside modal
  const firstFocusableElement = modal.querySelectorAll(focusableElements)[0];
  const focusableContent = modal.querySelectorAll(focusableElements);
  // Get last element to be focused inside modal
  const lastFocusableElement = focusableContent[focusableContent.length - 1];

  // Keydown function for tab to override tab behaviour
  document.addEventListener('keydown', (e) => {
    const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    if (e.shiftKey) { // if shift key pressed for shift + tab combination
      if (document.activeElement === firstFocusableElement) {
        lastFocusableElement.focus(); // add focus for the last focusable element
        e.preventDefault();
      }
    } else if (document.activeElement === lastFocusableElement) {
      firstFocusableElement.focus(); // add focus for the first focusable element
      e.preventDefault();
    }
  });

  firstFocusableElement.focus();
});
