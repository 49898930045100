window.addEventListener('DOMContentLoaded', () => {
  if (document.getElementById('medical-translation')) {
    const targetText = document
      .querySelector('#medical-translation')
      .getAttribute('text');
    document.querySelectorAll('.table.table--full td').forEach((td) => {
      const te = td;
      const match = te.textContent.match(targetText);
      if (match) {
        te.style = 'vertical-align: top;';
      }
    });
  }
});
