import { Controller } from 'stimulus';

export default class extends Controller {
  // eslint wants you to use a static method here but we can't
  // or stimulus can't find it
  // eslint-disable-next-line class-methods-use-this
  track(event) {
    const button = event.target;
    const eventParams = JSON.parse(button.getAttribute('data-tracking-params'));
    window.gtag('event', 'click', eventParams);
    // needs testing in prod!
    // if we ever add other tracking i.e. not google we'll need to add it here
  }
}
