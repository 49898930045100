import Glide, { Autoplay, Controls } from '@glidejs/glide/dist/glide.modular.esm';
import { Controller } from 'stimulus';

const marketingMsgActiveClass = 'carousel__marketing-message--active';

const seconds = (s) => s * 1000;

const glidejsOptions = {
  autoplay: seconds(5),
  hoverpause: false,
};

const glidejsModules = {
  Autoplay,
  Controls,
};

export default class extends Controller {
  static targets = ['carousel', 'marketingMessage']

  connect() {
    const glideInstance = new Glide(this.element, glidejsOptions);
    glideInstance.on('run', () => {
      this.setActiveMarketingMsgClasses(glideInstance);
    });

    this.setActiveMarketingMsgClasses(glideInstance);

    glideInstance.mount(glidejsModules);
  }

  setActiveMarketingMsgClasses(glideInstance) {
    this.marketingMessageTargets.forEach((msg) => {
      const marketingMessageIndex = msg.getAttribute('data-carousel-slide-index');
      if (parseInt(marketingMessageIndex, 10) === glideInstance.index) {
        msg.classList.add(marketingMsgActiveClass);
      } else {
        msg.classList.remove(marketingMsgActiveClass);
      }
    });
  }
}
