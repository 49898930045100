/* eslint-disable no-param-reassign */

const takeWhile = (arr, predicate) => {
  const output = [];
  /* eslint-disable no-restricted-syntax */
  /**
   * deciding to disable this rule. 'for of' is cleaner than basic
   * for loop and .forEach won't let you break
   */
  for (const item of arr) {
    if (predicate(item)) output.push(item);
    else break;
  }
  return output;
};

const processTable = (table) => {
  const nodesToRemove = [];
  [...table.rows].forEach((row, currentRowIndex, allRows) => {
    const lastRow = allRows.length - 1;
    if (currentRowIndex === lastRow) return;

    [...row.cells].forEach((cell, currentCellIndex) => {
      if (nodesToRemove.includes(cell)) return;

      const belowRows = allRows.slice(currentRowIndex + 1);
      const belowCells = belowRows.map(
        (belowRow) => belowRow.cells[currentCellIndex],
      );

      const cellsToMerge = takeWhile(
        belowCells,
        (c) => c.textContent === cell.textContent,
      );

      const numberOfCellsToMerge = cellsToMerge.length;

      if (numberOfCellsToMerge > 0) {
        cell.rowSpan = numberOfCellsToMerge + 1;
        cellsToMerge.forEach((node) => {
          nodesToRemove.push(node);
        });
      }
    });
  });

  nodesToRemove.forEach((node) => node.remove());
};

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.table--full').forEach(processTable);
});
