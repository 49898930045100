import { Controller } from 'stimulus';

const ACTIVE_BUTTON_CLASS = 'benefit-box-list__filter-button--active';
const HIDDEN_BENEFIT_BOX_CLASS = 'benefit-box--hidden';

window.addEventListener('DOMContentLoaded', () => {
  /**
   * This is so HACKY :( Sorry
   *
   * but I couldn't work out how to make it work with the way JS has been done here.
   *
   * So, if there is a tag item in the query string and IF it matches one of the buttons
   * it simulates a click on it.
   */
  if (window.URLSearchParams === undefined) return;
  const urlParams = new URLSearchParams(window.location.search);
  const tag = urlParams.get('tag');
  if (tag) {
    const relevantButton = document.querySelector(
      `[data-filter-tag="${tag}" i]`, // the i makes it case insensitive
    );
    if (relevantButton) relevantButton.click();
  }
});

export default class extends Controller {
  static targets = [
    'filterable',
    'filterButton',
    'viewAllButton',
    'searchInput',
    'resultsNotFoundText',
    'blankTile',
  ];

  connect() {
    this.setBlankTileClass();
  }

  viewAll() {
    this.filterableTargets.forEach((target) => {
      this.constructor.showBenefit(target);
    });

    this.filterButtonTargets.forEach((button) => {
      button.classList.remove(ACTIVE_BUTTON_CLASS);
    });

    this.viewAllButtonTargets[0].classList.add(ACTIVE_BUTTON_CLASS);

    this.setBlankTileClass();
  }

  search(event) {
    const searchTerm = event.target.value.trim().toLowerCase();

    const numberOfBenefits = this.filterableTargets.length;
    let hiddenBenefitsCount = 0;
    this.filterableTargets.forEach((target) => {
      const searchKeywords = target
        .getAttribute('data-search-keywords')
        .toLowerCase();
      if (searchKeywords.indexOf(searchTerm) === -1) {
        this.constructor.hideBenefit(target);
        hiddenBenefitsCount += 1;
      } else {
        this.constructor.showBenefit(target);
      }
    });

    if (numberOfBenefits === hiddenBenefitsCount) {
      this.resultsNotFoundTextTargets[0].classList.add(
        'benefit-box-list__search-no-results-text--visible',
      );
    } else {
      this.resultsNotFoundTextTargets[0].classList.remove(
        'benefit-box-list__search-no-results-text--visible',
      );
    }

    this.setBlankTileClass();
  }

  filter(event) {
    const filterByButton = event.target;
    this.filterButtonTargets.forEach((button) => {
      button.classList.remove(ACTIVE_BUTTON_CLASS);
    });
    this.viewAllButtonTargets[0].classList.remove(ACTIVE_BUTTON_CLASS);

    filterByButton.classList.add(ACTIVE_BUTTON_CLASS);

    const filterBy = filterByButton.getAttribute('data-filter-tag');
    this.filterableTargets.forEach((target) => {
      const targetTags = target.getAttribute('data-filter-tags');
      if (targetTags.indexOf(filterBy) === -1) {
        this.constructor.hideBenefit(target);
      } else {
        this.constructor.showBenefit(target);
      }
    });

    this.setBlankTileClass();
  }

  static hideBenefit(element) {
    element.classList.add(HIDDEN_BENEFIT_BOX_CLASS);
  }

  static showBenefit(element) {
    element.classList.remove(HIDDEN_BENEFIT_BOX_CLASS);
  }

  setBlankTileClass() {
    const visibleBenefitBoxes = this.filterableTargets
      // filter out boxes that are hidden from filter or search
      // [].slice.call used for IE11
      .filter(
        (box) => [].slice.call(box.classList).indexOf(HIDDEN_BENEFIT_BOX_CLASS) === -1,
      );

    const benefitBoxesPerRow = this.currentItemsPerRow;

    const blankTileIsRequired = visibleBenefitBoxes.length % benefitBoxesPerRow !== 0;

    const blankTileElement = this.blankTileTargets[0];

    if (blankTileIsRequired) {
      this.constructor.showBenefit(blankTileElement);
    } else {
      this.constructor.hideBenefit(blankTileElement);
    }
  }

  // I don't like this at all but I can't think of a better way to do it.
  // If the flex items (benefit boxes) don't fill the grid we pad with a
  // blank box. To determine whether or not we need the blank box we do
  // setBlankTileClass() which works it out by checking how many visible
  // items we have % the number per row - we can't just hard code the
  // number of items per row as 4 as it's also possible to use 3 per row
  // and we can't put that value in a data attribute because the grid is
  // responsive - so we always need to check it against the
  // currentItemsPerRow(). However if we have change the grid so it works
  // differently (ie doesn't use flexBasis or a percentage) this will
  // break
  get currentItemsPerRow() {
    return parseInt(
      100
        / parseInt(
          getComputedStyle(this.filterableTargets[0]).flexBasis.replace('%'),
          10,
        ),
      10,
    );
  }
}
