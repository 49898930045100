// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import '@stimulus/polyfills';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

// require('@rails/ujs').start()
// require('@rails/activestorage').start()
// require('channels')

import '../components';
import '../stylesheets/application.scss';
import '../medical_table_hack';
import '../feedback_survey';

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true);

const application = Application.start();
const componentsContext = require.context(
  '../../components',
  true,
  /(.*)\/.+\.js$/,
);
application.load(definitionsFromContext(componentsContext));
